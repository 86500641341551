import React, { createContext, useContext, useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";

import BackdropSpinner from "components/UI/BackdropSpinner";

interface IAppContext {
  isMobile: boolean;
  withLoader<T>(callback: PromiseLike<T>): Promise<Awaited<T>>;
}

interface IAppProvider {
  children: React.ReactNode;
}

const AppContext = createContext<IAppContext | undefined>(undefined);

export const AppProvider: React.FC<IAppProvider> = ({ children }) => {
  const theme = useTheme();
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= theme.breakpoints.values.xs
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.onresize = () =>
      window.innerWidth <= theme.breakpoints.values.xs
        ? setIsMobile(true)
        : setIsMobile(false);
  }, []);

  const withLoader = async (callback: PromiseLike<any>) => {
    setLoading(true);
    const result = await callback;
    setLoading(false);
    return result;
  };

  return (
    // @ts-ignore
    <AppContext.Provider value={{ isMobile, withLoader }}>
      <BackdropSpinner loading={loading} />
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within the AppProvider!");
  }
  return context;
};
