import React from "react";
import { styled } from "@mui/material/styles";
import { CircularProgress, Backdrop } from "@mui/material";

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, 0)",
  zIndex: theme.customZIndex.spinner,
}));

interface IBackdropSpinner {
  loading: boolean;
}

const BackdropSpinner: React.FC<IBackdropSpinner> = ({ loading }) => (
  <StyledBackdrop open={loading}>
    <CircularProgress size={120} />
  </StyledBackdrop>
);

export default BackdropSpinner;
