import { useCallback } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { toast } from "react-hot-toast";

import { Roles } from "utils/enums";

const REDIRECT_URI = window.location.origin;

export const useAuth = () => {
  const { keycloak, initialized } = useKeycloak();

  const login = useCallback(() => {
    keycloak.login({ redirectUri: REDIRECT_URI });
  }, []);

  const logout = useCallback(() => {
    keycloak.logout({ redirectUri: REDIRECT_URI });
  }, []);

  const setOnTokenExpired = useCallback(() => {
    keycloak.onTokenExpired = () => {
      keycloak.updateToken(30).catch(() => {
        toast.error("Ошибка при обновлении токена авторизации.");
        logout();
      });
    };
  }, []);

  const hasRole = useCallback((role: Roles) => {
    return keycloak.hasRealmRole(role);
  }, []);

  return {
    initialized,
    authenticated: keycloak.authenticated,
    username: keycloak.tokenParsed?.username || "Пользователь",
    roles: keycloak.realmAccess?.roles || [],
    token: keycloak.token,
    login,
    logout,
    setOnTokenExpired,
    hasRole,
  };
};

export default useAuth;
