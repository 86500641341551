import React from "react";
import loadable from "@loadable/component";
import {
  RouterProvider,
  Route,
  Navigate,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import useAxios from "hooks/useAxios";
import useAuth from "hooks/useAuth";
import { RoutePaths } from "utils/enums";
const LobbyRoutes = loadable(() => import("pages/Lobby"));
const HomeRoutes = loadable(() => import("pages/Home"));

const AppRoutes = () => {
  const { initialized: authInitialized, authenticated } = useAuth();
  const { initialized: axiosInitialized } = useAxios();

  if (!authInitialized || !axiosInitialized) {
    return null;
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path={`${RoutePaths.LOBBY}/*`} element={<LobbyRoutes />} />
        <Route path={`${RoutePaths.HOME}/*`} element={<HomeRoutes />} />
        <Route
          path={"/*"}
          element={
            <Navigate
              replace
              to={authenticated ? RoutePaths.HOME : RoutePaths.LOBBY}
            />
          }
        />
      </>
    )
  );

  return <RouterProvider router={router} />;
};

export default AppRoutes;
