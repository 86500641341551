export enum Roles {
  USER = "MARKET_PORTAL_USER",
  ADMIN = "MARKET_PORTAL_ADMIN",
}

export enum RoutePaths {
  LOBBY = "/lobby",
  LOGIN = "/lobby/login",
  RECOVERY = "/lobby/recovery",
  HOME = "/home",
  ACCOUNTS = "/home/accounts",
  ORDERS = "/home/orders",
  NEW_ORDER = "/home/new-order",
  CLIENT_DATA = "/home/new-order/client-data",
  BALANCE = "/home/new-order/balance",
  SERVICES = "/home/new-order/servises",
  PRODUCTS = "/home/new-order/products",
}

export enum TableColumnKeys {
  DELETE = "delete",
  OPEN = "open",
}

export enum OrderTypes {
  SERVICE = "Работа",
  PRODUCT = "Товар",
}
