import Keycloak from "keycloak-js";

import config from "env-config.json";

const keycloak = new Keycloak({
  url: config.KEYCLOAK_URL.includes("$")
    ? "https://tst-keycloak.msk.csat.ru/auth/"
    : config.KEYCLOAK_URL,
  realm: config.KEYCLOAK_REALM.includes("$")
    ? "market-service"
    : config.KEYCLOAK_REALM,
  clientId: config.KEYCLOAK_CLIENT_ID.includes("$")
    ? "market_portal_client"
    : config.KEYCLOAK_CLIENT_ID,
});

export default keycloak;
