import React from "react";
import { ThemeProvider } from "@emotion/react/macro";
import { Toaster } from "react-hot-toast";
import { ReactKeycloakProvider } from "@react-keycloak/web";

import theme, { GlobalStyles } from "theme";
import keycloakClient from "keycloak";
import { AppProvider } from "contexts/app";
import AppRoutes from "pages";

const toastStyle = {
  minWidth: 280,
  maxWidth: "min-content",
};

const App = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <Toaster
      position={"top-left"}
      reverseOrder={true}
      toastOptions={{
        success: { style: toastStyle },
        error: { style: toastStyle },
      }}
    />
    <ReactKeycloakProvider authClient={keycloakClient}>
      <AppProvider>
        <AppRoutes />
      </AppProvider>
    </ReactKeycloakProvider>
  </ThemeProvider>
);

export default App;
