import React from "react";
import { Global } from "@emotion/react/macro";
import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Theme {
    customColor: {
      lightGrey: string;
      darkGrey: string;
      yellow: string;
      blue: string;
    };
    customHeight: {
      toolbar: {
        primary: number;
        secondary: number;
      };
    };
    customZIndex: {
      header: number;
      footer: number;
      spinner: number;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    customColor?: {
      lightGrey: string;
      darkGrey: string;
      yellow: string;
      blue: string;
    };
    customHeight?: {
      toolbar: {
        primary: number;
        secondary: number;
      };
    };
    customZIndex?: {
      header: number;
      footer: number;
      spinner: number;
    };
  }
}

const theme = createTheme({
  customColor: {
    lightGrey: "#f8f9fa",
    darkGrey: "#eff1f4",
    yellow: "#ffd800",
    blue: "#1976d2",
  },
  customHeight: {
    toolbar: {
      primary: 60,
      secondary: 96,
    },
  },
  customZIndex: {
    header: 1_000,
    footer: 900,
    spinner: 2_000,
  },
  palette: {
    primary: {
      main: "#708090",
    },
    text: {
      secondary: "#00000061",
    },
  },
  breakpoints: {
    values: {
      xs: 480,
      sm: 768,
      md: 1024,
      lg: 1200,
      xl: 1536,
    },
  },
});

export const GlobalStyles = () => (
  <Global
    styles={{
      body: {
        padding: 0,
        margin: 0,
        backgroundColor: "#eff1f4",
        fontFamily: "Roboto",
        fontSize: 16,
      },
    }}
  />
);

export default theme;
