import { useState, useEffect, useRef } from "react";
import axios, {
  AxiosResponse,
  AxiosError,
  InternalAxiosRequestConfig,
  AxiosRequestConfig,
} from "axios";
import toast from "react-hot-toast";

import useAuth from "hooks/useAuth";

interface IRequestBody {
  [key: string]: any;
}
interface IResponse<T = any> {
  success: boolean;
  data: T;
  message: string;
}

const API_BASE_URL = "/api/v1/";

const useAxios = () => {
  const { token, logout, setOnTokenExpired } = useAuth();
  const [requestInterceptor, setRequestInterceptor] = useState<
    number | undefined
  >();
  const instanceRef = useRef(axios.create({ baseURL: API_BASE_URL }));

  useEffect(() => {
    instanceRef.current.interceptors.response.use(
      // @ts-ignore
      responseInterceptor,
      errorInterceptor
    );
    setOnTokenExpired();
  }, []);

  useEffect(() => {
    if (typeof requestInterceptor !== "undefined") {
      instanceRef.current.interceptors.request.eject(requestInterceptor);
      setRequestInterceptor(undefined);
    }
    if (token) {
      const authInterceptor = getAuthInterceptor(token);
      const interceptorId =
        instanceRef.current.interceptors.request.use(authInterceptor);
      setRequestInterceptor(interceptorId);
    }
  }, [token]);

  const responseInterceptor = (response: AxiosResponse<IResponse>) => {
    const { data } = response;
    if (!data.success) {
      toast.error(data?.message || "Неизвестная ошибка сервера");
    }
    return data;
  };

  const errorInterceptor = async (error: AxiosError<any>) => {
    const status = error.response?.status;
    const message =
      error.response?.data?.message ||
      error.response?.statusText ||
      "Неизвестная ошибка сервера";
    toast.error(message);
    if (status === 401) {
      logout();
    }
    return Promise.resolve({
      success: false,
      data: null,
      message,
    });
  };

  const getAuthInterceptor =
    (token: string) => (config: InternalAxiosRequestConfig) =>
      ({
        ...config,
        withCredentials: true,
        headers: {
          ...(config.headers || {}),
          Authorization: `Bearer ${token}`,
        },
      } as InternalAxiosRequestConfig);

  const axiosGet = <T = any>(
    url: string,
    config?: AxiosRequestConfig<any>
  ): Promise<IResponse<T>> => instanceRef.current.get(url, config);

  const axiosDelete = <T = any>(
    url: string,
    config?: AxiosRequestConfig<any>
  ): Promise<IResponse<T>> => instanceRef.current.delete(url, config);

  const axiosPost = <T = any>(
    url: string,
    body: IRequestBody,
    config?: AxiosRequestConfig<any>
  ): Promise<IResponse<T>> => instanceRef.current.post(url, body, config);

  const axiosPut = <T = any>(
    url: string,
    body: IRequestBody,
    config?: AxiosRequestConfig<any>
  ): Promise<IResponse<T>> => instanceRef.current.put(url, body, config);

  return {
    initialized:
      (!token && requestInterceptor === undefined) ||
      (!!token && typeof requestInterceptor === "number"),
    axiosGet,
    axiosDelete,
    axiosPost,
    axiosPut,
  };
};

export default useAxios;
